<template>
  <div>
    <div v-if="!ViewTimeAllocation">
      <b-form-group
        label-cols="0"
        label-cols-sm="4"
        :label="
          $getVisibleNames('mesh.timeallocation', false, 'División de tiempo')
        "
        label-for="input-time-allocation"
        class="label my-2 mx-0"
      >
        <div class="d-flex">
          <button
            v-if="selectedTimeAllocations.length > 0"
            @click="$bvModal.show(`selected-time-allocation-${object_id}`)"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="``"
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
        </div>
        <div class="campus-container">
          <div
            v-for="(time_allocation, index) in timeAllocationsFilter"
            :key="index"
            class="campus-div"
          >
            <div class="d-flex">
              <div class="d-block w-100 align-self-center mr-1">
                {{ time_allocation.name }}
              </div>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
                class="btn-div pb-1 ml-1 m-0"
                @click="deleteTimeAllocation(time_allocation.id)"
              ></button-delete>
            </div>
          </div>
        </div>
      </b-form-group>
    </div>
    <div v-else>
      <b-form-group
        label-cols="0"
        label-cols-sm="4"
        :label="
          $getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )
        "
        label-for="input-study_environments"
        class="label my-2 mx-0"
      >
        <div class="d-flex">
          <button
            v-if="selectedStudyEnvironments.length > 0"
            @click="$bvModal.show(`selected-study-enviroment-${object_id}`)"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="``"
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
        </div>
        <div class="campus-container">
          <div
            v-for="(study_environment, index) in studyEnvironmentsFilter"
            :key="index"
            class="campus-div"
          >
            <div class="d-flex">
              <div class="d-block w-100 align-self-center mr-1">
                {{ study_environment.name }}
              </div>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
                class="btn-div pb-1 ml-1 m-0"
                @click="deleteStudyEnvironment(study_environment.id)"
              ></button-delete>
            </div>
          </div>
        </div>
        <!-- <v-select
          id="input-study_environments"
          v-model="temp.study_environments"
          :options="studyEnvironments"
          :reduce="(studyEnvironments) => studyEnvironments.id"
          :placeholder="`${
            studyEnvironments.length > 0
              ? String(
                  'Seleccione uno o varios ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          multiple
          :selectable="(option) => !temp.study_environments.includes(option.id)"
          :dropdown-should-open="dropdownShouldOpen"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template> 
        </v-select>
        <b-tooltip
          target="input-study_environments"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="studyEnvironments.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>-->
      </b-form-group>
    </div>
    <b-modal
      :id="`selected-time-allocation-${object_id}`"
      :title="`Seleccione  ${$getVisibleNames(
        'mesh.timeallocation',
        true,
        'Divisiones de tiempo'
      )}`"
      size="md"
      @hide="
        () => {
          selected_time_allocation = [];
        }
      "
      hide-footer
    >
      <b-form-select
        v-model="selected_time_allocation"
        value-field="id"
        text-field="name"
        multiple
        :select-size="
          7 > selectedTimeAllocations.length
            ? selectedTimeAllocations.length
            : 7
        "
      >
        <b-form-select-option
          v-for="time_allocation in selectedTimeAllocations"
          :key="time_allocation.id"
          :value="time_allocation.id"
        >
          {{ time_allocation.name }}
        </b-form-select-option>
      </b-form-select>
      <div class="row">
        <div class="col" style="text-align: right">
          <b-button class="mr-1" @click="emitTimeAllocation()" size="sm"
            >Guardar</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="`selected-study-enviroment-${object_id}`"
      :title="`Seleccione  ${$getVisibleNames(
        'mesh.studyenvironment',
        true,
        'Ambientes de Aprendizaje'
      )}`"
      size="md"
      @hide="
        () => {
          selected_study_environment = [];
        }
      "
      hide-footer
    >
      <!-- :options="selectedStudyEnvironments" -->
      <b-form-select
        v-model="selected_study_environment"
        value-field="id"
        text-field="name"
        multiple
        :select-size="
          7 > selectedStudyEnvironments.length
            ? selectedStudyEnvironments.length
            : 7
        "
      >
        <b-form-select-option
          v-for="study_environment in selectedStudyEnvironments"
          :key="study_environment.id"
          :value="study_environment.id"
        >
          {{ study_environment.name }}
        </b-form-select-option>
      </b-form-select>
      <div class="row">
        <div class="col" style="text-align: right">
          <b-button class="mr-1" @click="emitStudyEnvironment()" size="sm"
            >Guardar</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";
export default {
  name: "SelectedStudyEnvironmentTimeAllocation",
  components: {
    // vSelect,
  },
  props: {
    object_id: {
      required: true,
    },
    ViewTimeAllocation: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      temp: {
        study_environments: [],
        time_allocations: [],
      },
      selected_study_environment: [],
      selected_time_allocation: [],
    };
  },
  computed: {
    ...mapGetters({
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      timeAllocations: names.TIME_ALLOCATIONS,
    }),
    selectedStudyEnvironments() {
      return this.studyEnvironments.filter(
        (x) => !this.temp.study_environments.includes(x.id)
      );
    },
    studyEnvironmentsFilter() {
      return this.studyEnvironments.filter((x) =>
        this.temp.study_environments.includes(x.id)
      );
    },
    selectedTimeAllocations() {
      return this.timeAllocations.filter(
        (x) =>
          !this.temp.time_allocations.includes(x.id) && x.has_study_environments
      );
    },
    timeAllocationsFilter() {
      return this.timeAllocations.filter((x) =>
        this.temp.time_allocations.includes(x.id)
      );
    },
  },
  methods: {
    // dropdownShouldOpen(VueSelect) {
    //   if (this.studyEnvironments.length > 0) {
    //     return VueSelect.open;
    //   }
    //   return VueSelect.search.length !== 0 && VueSelect.open;
    // },
    async emitStudyEnvironment() {
      await this.$emit(
        "update_study_environment",
        this.selected_study_environment
      );
      for await (let study_environment of this.selected_study_environment)
        this.temp.study_environments.push(study_environment);
      await this.$bvModal.hide(`selected-study-enviroment-${this.object_id}`);
    },
    deleteStudyEnvironment(study_environment_id) {
      const index = this.temp.study_environments.findIndex(
        (x) => x == study_environment_id
      );
      if (index != -1) {
        this.temp.study_environments.splice(index, 1);
        this.$emit("remove_study_environment", study_environment_id);
      }
    },
    async emitTimeAllocation() {
      await this.$emit("update_time_allocation", this.selected_time_allocation);
      for await (let time_allocation of this.selected_time_allocation)
        this.temp.time_allocations.push(time_allocation);
      await this.$bvModal.hide(`selected-time-allocation-${this.object_id}`);
    },
    deleteTimeAllocation(time_allocation_id) {
      const index = this.temp.time_allocations.findIndex(
        (x) => x == time_allocation_id
      );
      if (index != -1) {
        this.temp.time_allocations.splice(index, 1);
        this.$emit("remove_time_allocation", time_allocation_id);
      }
    },
  },
  mounted() {},
  created() {
    if (this.ViewTimeAllocation && !isNaN(this.object_id))
      this.$store
        .dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION, {
          time_allocation: this.object_id,
        })
        .then((response) => {
          this.temp.study_environments = response
            .filter((x) => x.time_allocation == this.object_id)
            .map((x) => x.study_environment);
        });
    if (!this.ViewTimeAllocation && !isNaN(this.object_id))
      this.$store
        .dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION, {
          study_environment: this.object_id,
        })
        .then((response) => {
          this.temp.time_allocations = response
            .filter((x) => x.study_environment == this.object_id)
            .map((x) => x.time_allocation);
        });
  },
};
</script>

<style scoped>
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  /* text-align: center; */
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.btn-div {
  color: white;
}
</style>