var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.ViewTimeAllocation)?_c('div',[_c('b-form-group',{staticClass:"label my-2 mx-0",attrs:{"label-cols":"0","label-cols-sm":"4","label":_vm.$getVisibleNames('mesh.timeallocation', false, 'División de tiempo'),"label-for":"input-time-allocation"}},[_c('div',{staticClass:"d-flex"},[(_vm.selectedTimeAllocations.length > 0)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(``),expression:"``",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-add",on:{"click":function($event){return _vm.$bvModal.show(`selected-time-allocation-${_vm.object_id}`)}}},[_c('b-icon',{attrs:{"font-scale":"1","icon":"plus-square"}})],1):_vm._e()]),_c('div',{staticClass:"campus-container"},_vm._l((_vm.timeAllocationsFilter),function(time_allocation,index){return _c('div',{key:index,staticClass:"campus-div"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100 align-self-center mr-1"},[_vm._v(" "+_vm._s(time_allocation.name)+" ")]),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Eliminar`),expression:"`Eliminar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-div pb-1 ml-1 m-0",on:{"click":function($event){return _vm.deleteTimeAllocation(time_allocation.id)}}})],1)])}),0)])],1):_c('div',[_c('b-form-group',{staticClass:"label my-2 mx-0",attrs:{"label-cols":"0","label-cols-sm":"4","label":_vm.$getVisibleNames(
          'mesh.studyenvironment',
          false,
          'Ambiente de Aprendizaje'
        ),"label-for":"input-study_environments"}},[_c('div',{staticClass:"d-flex"},[(_vm.selectedStudyEnvironments.length > 0)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(``),expression:"``",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-add",on:{"click":function($event){return _vm.$bvModal.show(`selected-study-enviroment-${_vm.object_id}`)}}},[_c('b-icon',{attrs:{"font-scale":"1","icon":"plus-square"}})],1):_vm._e()]),_c('div',{staticClass:"campus-container"},_vm._l((_vm.studyEnvironmentsFilter),function(study_environment,index){return _c('div',{key:index,staticClass:"campus-div"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100 align-self-center mr-1"},[_vm._v(" "+_vm._s(study_environment.name)+" ")]),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Eliminar`),expression:"`Eliminar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-div pb-1 ml-1 m-0",on:{"click":function($event){return _vm.deleteStudyEnvironment(study_environment.id)}}})],1)])}),0)])],1),_c('b-modal',{attrs:{"id":`selected-time-allocation-${_vm.object_id}`,"title":`Seleccione  ${_vm.$getVisibleNames(
      'mesh.timeallocation',
      true,
      'Divisiones de tiempo'
    )}`,"size":"md","hide-footer":""},on:{"hide":() => {
        _vm.selected_time_allocation = [];
      }}},[_c('b-form-select',{attrs:{"value-field":"id","text-field":"name","multiple":"","select-size":7 > _vm.selectedTimeAllocations.length
          ? _vm.selectedTimeAllocations.length
          : 7},model:{value:(_vm.selected_time_allocation),callback:function ($$v) {_vm.selected_time_allocation=$$v},expression:"selected_time_allocation"}},_vm._l((_vm.selectedTimeAllocations),function(time_allocation){return _c('b-form-select-option',{key:time_allocation.id,attrs:{"value":time_allocation.id}},[_vm._v(" "+_vm._s(time_allocation.name)+" ")])}),1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.emitTimeAllocation()}}},[_vm._v("Guardar")])],1)])],1),_c('b-modal',{attrs:{"id":`selected-study-enviroment-${_vm.object_id}`,"title":`Seleccione  ${_vm.$getVisibleNames(
      'mesh.studyenvironment',
      true,
      'Ambientes de Aprendizaje'
    )}`,"size":"md","hide-footer":""},on:{"hide":() => {
        _vm.selected_study_environment = [];
      }}},[_c('b-form-select',{attrs:{"value-field":"id","text-field":"name","multiple":"","select-size":7 > _vm.selectedStudyEnvironments.length
          ? _vm.selectedStudyEnvironments.length
          : 7},model:{value:(_vm.selected_study_environment),callback:function ($$v) {_vm.selected_study_environment=$$v},expression:"selected_study_environment"}},_vm._l((_vm.selectedStudyEnvironments),function(study_environment){return _c('b-form-select-option',{key:study_environment.id,attrs:{"value":study_environment.id}},[_vm._v(" "+_vm._s(study_environment.name)+" ")])}),1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.emitStudyEnvironment()}}},[_vm._v("Guardar")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }